.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  justify-content: space-between;
}

h2{
  font-size: 64px;
  margin-top: 64px;
}

.Content{
  margin: 64px 0;
  justify-content: center;
  display: flex;
  align-items: center;
  flex: 1;
}

footer > img{
  
    padding: 0 8px;
  
}

.links{
  justify-content: center;
  display: flex;
  margin-bottom: 24px;
}

ul{
  display: flex;
  padding: 0;
}

li{
  display: block;
  margin: 0 8px;
  cursor: pointer;
}

.content-footer{
  font-weight: 600;
  margin-bottom: 24px;
}